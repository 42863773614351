<template>
  <b-card
    class="salesreturnbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salesreturnbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货单ID"
            label-for="returnbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_id"
              size="sm"
              v-model="salesreturnbounditem.returnbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="salesreturnbounditem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单产品明细ID"
            label-for="orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="orderitem_id"
              size="sm"
              v-model="salesreturnbounditem.orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单ID"
            label-for="outbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_id"
              size="sm"
              v-model="salesreturnbounditem.outbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单明细ID"
            label-for="outbounditem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbounditem_id"
              size="sm"
              v-model="salesreturnbounditem.outbounditem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="salesreturnbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="salesreturnbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="salesreturnbounditem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="salesreturnbounditem.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="salesreturnbounditem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="salesreturnbounditem.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="计划出库数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="salesreturnbounditem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际出库数量"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="salesreturnbounditem.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方实收数量"
            label-for="receiveqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receiveqty"
              size="sm"
              v-model="salesreturnbounditem.receiveqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否已退回仓库"
            label-for="is_return"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_return"
              size="sm"
              v-model="salesreturnbounditem.is_return"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预计退货数量"
            label-for="salesreturnqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty"
              size="sm"
              v-model="salesreturnbounditem.salesreturnqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际退货数量(良品)  "
            label-for="salesreturnqty_good"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty_good"
              size="sm"
              v-model="salesreturnbounditem.salesreturnqty_good"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际退货数量(不良品)  "
            label-for="salesreturnqty_bad"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="salesreturnqty_bad"
              size="sm"
              v-model="salesreturnbounditem.salesreturnqty_bad"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="salesreturnbounditem.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="salesreturnbounditem.modify_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesreturnbounditemStore from './salesreturnbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      salesreturnbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesreturnbounditem')) store.registerModule('salesreturnbounditem', salesreturnbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesreturnbounditem')) store.unregisterModule('salesreturnbounditem')
    })

    const edit = function() {
      store.dispatch('salesreturnbounditem/edit', {id: this.id}).then(res => {
        this.salesreturnbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('salesreturnbounditem/view', {id: this.id}).then(res => {
        this.salesreturnbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('salesreturnbounditem/save', this.salesreturnbounditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-salesreturnbounditem-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>